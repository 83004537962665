<template>
  <div class="app">
    <Navbar :title="$t('newAdd.RechargeRecordTitle')" />
    <div class="tab-lists">
      <div
        class="tab-item"
        :class="[status === item.params.status ? 'on-tab-item' : '']"
        v-for="(item, index) in tabLists"
        :key="index"
        @click="handleTabItme(item.params.status)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="wrap">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('Hint.notMore') + '...'"
        :loading-text="$t('Hint.loading')"
        @load="onLoad"
      >
        <!-- <scroll-view class="list" scroll-y @scrolltolower="handleLoadMore"> -->
        <div class="list-item" v-for="(item, index) in lists" :key="index">
          <div class="list-item-left">
            <div class="list-item-title">
              <span class="list-item-title-text">{{ item.type }}</span>
              <!-- <text v-if="item.status==='已完成'" class="list-item-title-sub">（{{item.status}}）2</text> -->
              <span
                v-if="item.status === '已完成'"
                class="list-item-title-sub"
                >{{ `（${$t("newAdd.succeed")}）` }}</span
              >
              <!-- <text v-else class="list-item-title-sub-green">（{{item.status}}）3</text> -->
              <span v-else class="list-item-title-sub-green"
                >（{{
                  item.status === "待审核"
                    ? $t("newAdd.toAudit")
                    : $t("newAdd.rests")
                }}）</span
              >
            </div>
            <span class="list-item-indate">{{ dateEvent(item.indate) }}</span>
          </div>
          <div class="list-item-right">
            <!-- language-ch 订单号 -->
            <span class="list-item-order"
              >{{ $t("newAdd.OrderNum") }}&nbsp;{{ item.payid }}</span
            >
            <div class="list-item-mbox">
              <span class="list-item-money"> {{ item.money }}</span>
              <!-- language-ch 到账￥ -->
              <span
                class="list-item-addMoney"
                v-if="item.status === '已完成' && item.money !== item.addMoney"
                >({{ $t("newAdd.Arrive") }} {{ item.addMoney }})</span
              >
            </div>
          </div>
        </div>
        <!-- language-ch 没有任何记录 -->
        <!-- <div v-if="alreadyLastPage" class="nolist">
        There's no record of anything
      </div> -->
        <!-- </scroll-view> -->
      </van-list>
    </div>
  </div>
</template>

<script>
import { NavBar, cell, icon, field, Dialog, Toast, List } from "vant";
import { GET_RECHARGE_LISTS } from "@/api";
import { formatDate } from "@/utils/tools";
import Navbar from "@/components/JNav";
export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [List.name]: List,
    Navbar,
  },
  data() {
    return {
      loading: false,
      finished: false,
      status: 0,
      alreadyLastPage: false,
      pages: 1,
      lists: [],
      tabLists: [
        {
          title: this.$t("newAdd.all"),
          params: { status: 0 },
        },
        {
          title: this.$t("newAdd.Completed"),
          params: { status: 1 },
        },
        {
          title: this.$t("newAdd.unfinished"),
          params: { status: 2 },
        },
      ],
      stateObj: {
        已汇款: "success",
        未通过: "not pass",
        汇款中: "processing",
        idpay: "Pay on behalf of",
      },
    };
  },

  mounted() {},

  methods: {
    onLoad() {
      this.getLists();
    },
    handleTabItme(status) {
      this.status = status;
      this.alreadyLastPage = false;
      this.pages = 1;
      this.lists = [];
      this.loading = false;
      this.finished = false;
      this.getLists();
    },
    handleLoadMore() {
      if (!this.alreadyLastPage) {
        this.getLists();
      }
    },
    getLists() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LISTS({
        status: this.status,
        page: this.pages,
      })
        .then((r) => {
          Toast.clear();
          this.loading = false;
          if (r.data.ret === 1) {
            Toast.clear();
            let dataLists = r.data.data.lists;
            if (r.data.data.currentPage > 1) {
              this.pages++;
              for (let i = 0; i < dataLists.length; i++) {
                this.lists.push(dataLists[i]);
              }
              if (r.data.data.currentPage === this.pages) {
                this.finished = true;
                this.loading = false;
              } else {
                this.finished = false;
                this.loading = true;
              }
            } else {
              Toast.clear();
              this.lists = dataLists;
              this.finished = true;
              this.loading = false;
            }

            this.alreadyLastPage = this.pages === 1 && dataLists.length === 0;
          } else {
            Toast.clear();
            this.$toast(r.data.msg);
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          Toast.clear();
          this.loading = false;
          console.error(e);
        });
    },
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
  },
};
</script>

<style scoped lang="less">
@Color: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: var(--bg1);
.app {
  flex: 1;
  width: 100%;
  height: 100vh;
  // background-color: #f4f8f9;
  background: var(--bg);
  > .tab-lists {
    display: flex;
    align-items: center;
    // background-color: #fff;
    background: var(--bg2);
    // border-bottom: calc(1rem / 16) solid #f4f8f9;
    > .tab-item {
      width: 33.33%;
      height: calc(40rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(12rem / 16);
      // color: #222;
      color: var(--gray1);
    }
    > .on-tab-item {
      // color: #dc1323;
      color: var(--theme);
      font-weight: bold;
    }
  }
}
.wrap {
  height: calc(100vh - (46rem / 16) - (40rem / 16));
}
.list {
  flex: 1;
  width: 100%;
  height: calc(100vh - (41rem / 16) - (44rem / 16));
  background-color: #ffffff;
}
.nolist {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  padding-top: calc(25rem / 16);
  padding-bottom: calc(25rem / 16);
  text-align: center;
  padding-bottom: calc(12rem / 16);
  color: #999999;
  margin: auto;
}
.list-item {
  width: 100%;
  padding: calc(20rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: calc(1rem / 16);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  box-sizing: border-box;
}
.list-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list-item-title-text {
  font-size: calc(23rem / 16);
  // color: #242424;
  color: var(--textColor);
}
.list-item-title-sub,
.list-item-title-sub-green {
  font-size: calc(12rem / 16);
  color: #1bb019;
}
.list-item-title-sub-green {
  color: #dc1323;
}
.list-item-reason {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  color: #ff6e1c;
  width: calc(520rem / 16);
}
.list-item-bank {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  // color: #888888;
  color: var(--gray1);
}
.list-item-indate {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  // color: #888888;
  color: var(--gray1);
}
.list-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.list-item-order {
  font-size: calc(10rem / 16);
  // color: #999999;
  color: var(--gray1);
}
.list-item-money {
  margin-top: calc(6rem / 16);
  font-size: calc(17rem / 16);
  color: #dc1323;
}
</style>